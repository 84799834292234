import "./main.scss";
import bookingLogo from "../../assets/booking_logo.svg";
import airbnbLogo from "../../assets/airbnb_logo.svg";
import instagramLogo from "../../assets/instagram_logo.svg";
import logo from "../../assets/logo.svg";
import { Footer, Gallery, Map } from "../index";

export function Main() {
  const isMobile = window.innerWidth < 768;

  return (
    <div className="background">
      <div className="shell">
        <img alt="page logo" className="heading" src={logo}></img>
        <span className="description desktop">
          <strong>Modern industrial appartments</strong> just 2 minutes from
          Canggu
        </span>
        <div className="links">
          <a target="blank" href="https://book.azurebali.com/listings/193935">
            <button className="button direct">Direct booking</button>
          </a>

          <a target="blank" href="https://www.booking.com/Share-uIjDgCz">
            <button className="button booking">
              <img src={bookingLogo} alt="Booking logo"></img>
            </button>
          </a>

          <a
            target="blank"
            href="https://www.airbnb.com/rooms/973614391479442457?locale=en&_set_bev_on_new_domain=1693214324_NDRiY2RlZmM4NjAx&source_impression_id=p3_1696859107_u1pDI74E5T%2FUtF6f&check_in=2023-11-08&guests=1&adults=1&check_out=2023-11-10"
          >
            <button className="button airbnb">
              <img src={airbnbLogo} className="air" alt="AirBnB logo"></img>
            </button>
          </a>
        </div>
        <span className="description mobile">
          <strong>Modern industrial appartments</strong> just 2 minutes from
          Canggu
        </span>
        {isMobile && <Gallery />}

        <div className="contact">
          <strong className="contact-us">Contact us</strong>
         <a href="tel:+6281138113600"><span className="phone-number">+62 811-3811-3600</span></a> 
         <a href="mailto:bydzak@gmail.com"><span className="email">mail@chingiack.com</span></a> 

          <div className="instagram">
            <a target="blank" href="https://www.instagram.com/chin.giack">
              <img
                className="logo"
                src={instagramLogo}
                alt="instagram logo"
              ></img>
              <strong className="nickname">chin.giack</strong>
            </a>
          </div>
          {isMobile && <Map />}
        </div>
      </div>
      <Footer />
    </div>
  );
}
