import "./gallery.scss";
import { useEffect, useRef, useState } from "react";
import carouselImage1 from "./../../assets/carousel/1.jpeg";
import carouselImage2 from "./../../assets/carousel/2.jpeg";
import carouselImage3 from "./../../assets/carousel/3.jpeg";
import carouselImage4 from "./../../assets/carousel/4.jpeg";

export function Gallery() {
  let [currentImage, setCurrentImage] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const options = [
    {
      id: 0,
      src: carouselImage1,
      text: "30\u00B2 of living space",
    },
    {
      id: 1,
      src: carouselImage2,
      text: "Working space with high speed internet",
    },
    {
      id: 2,
      src: carouselImage3,
      text: "Huge shared pool",
    },
    {
      id: 3,
      src: carouselImage4,
      text: "Your own fully equipped kitchen",
    },
  ];
  
  useEffect(()=>{
    sliderRef.current?.addEventListener("touchend",handleTouch);
  })

  function handleTouch() {
    setTimeout(() => {
      let sliderRect = sliderRef.current?.getBoundingClientRect();
      options.forEach((element) => {
        const imageRect = document
          .getElementById("image-" + element.id)
          ?.getBoundingClientRect();
        if (
          imageRect &&
          sliderRect &&
          imageRect.left >= sliderRect.left &&
          imageRect.right <= sliderRect.right
        ) {
          setCurrentImage(element.id);
        }
      });
    }, 700);
    
  }

  function setSlide(id: number) {
    setCurrentImage(id);
    const slider = sliderRef.current;
    if (slider) {
      const image = slider.children[id];
      if (image) {
        image.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }


  return (
    <section className="container">
      <div className="slider-wrapper">
        <div className="slider" ref={sliderRef}>
          {options.map((option) => {
            return (
              <img
                key={option.id}
                alt={option.text}
                src={option.src}
                id={"image-" + option.id}
              ></img>
            );
          })}
        </div>
        <div className="slider-nav">
          <span className="image-info">{options[currentImage].text}</span>
          <div className="navigation-buttons">
            {options.map((option) => {
              return (
                <button
                  key={option.id}
                  className={option.id === currentImage ? "selected" : ""}
                  onClick={() => setSlide(option.id)}
                ></button>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
