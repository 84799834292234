import './footer.scss';
import matfixLogo from '../../assets/matfix_logo.svg'
import footerLogo from '../../assets/footer_image.svg'


export function Footer() {
  return <a href='https://matfix.cz'>
    <div className='footer'>
    <img alt='matfix logo' src={matfixLogo}></img>
    <span className='creative-span'>
    creative
    <img alt='footer logo' src={footerLogo}></img>
    </span>
  </div></a>;
}
