import "./map.scss";

export function Map() {
  return (
    <div className="map">
      <iframe
        title="title"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCnPaBeqszXE95WgwBhEsSxfX0O9qE3B1I&q=Jl.Sunclad+Villa%2FLaki+Uma+Villa,+Jl.+Umalas+Klecung+No.10,+Kerobokan+Kelod,+Kuta+Utara,+Badung+Regency,+Bali+80361,+Indonesia/@-8.6660648,115.1567062,17z&zoom=14"
      ></iframe>
    </div>
  );
}